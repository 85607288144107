import {
  /* ChangeDetectionStrategy, */ ChangeDetectorRef,
  Component,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ErrorLayoutService } from '@services';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { Observable, timer } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'math-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TuiDestroyService],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  @HostBinding('class.math-app')
  readonly hostClassEnabled = true;

  errorLayout$: Observable<boolean>;
  regularLayout$: Observable<boolean>;

  constructor(
    public errorLayoutService: ErrorLayoutService,
    private router: Router,
    private destroy$: TuiDestroyService,
    cd: ChangeDetectorRef
  ) {
    const status = this.errorLayoutService.enabled$.pipe(
      tap(() =>
        timer(0)
          .pipe(takeUntil(destroy$))
          .subscribe(() => cd.markForCheck())
      )
    );

    this.errorLayout$ = status;
    this.regularLayout$ = status.pipe(map((v) => !v));

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationStart),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.errorLayoutService.disable();
      });
  }
}
