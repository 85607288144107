import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ErrorLayoutComponent } from './error-layout.component';

@NgModule({
  declarations: [ErrorLayoutComponent],
  exports: [ErrorLayoutComponent],
  imports: [CommonModule, RouterModule],
})
export class ErrorLayoutModule {}
