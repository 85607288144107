import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { SiteTitleService } from '@services';

@Component({
  selector: 'math-not-found',
  template: `
    <h1>Страница не найдена</h1>
    <article>Кажется, вы потерялись</article>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent {
  constructor(private titleService: SiteTitleService, private meta: Meta) {
    titleService.setTitle('Ууупс, вы попали не туда: 404 ошибка');
    const description = '404 ошибка';
    meta.addTag({ name: 'description', content: description });
  }
}
