import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorViewComponent } from './error-view.component';
import { NotFoundComponent } from './components/not-found.component';
import { ForbiddenComponent } from './components/forbidden.component';
import { BadRequestComponent } from './components/bad-request.component';
import { ServerErrorComponent } from './components/server-error.component';

@NgModule({
  declarations: [ErrorViewComponent, NotFoundComponent, ForbiddenComponent, BadRequestComponent, ServerErrorComponent],
  exports: [ErrorViewComponent],
  imports: [CommonModule],
})
export class ErrorViewModule {}
