import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ErrorType } from '@services/error-layout.service';

@Component({
  selector: 'math-error-view',
  templateUrl: './error-view.component.html',
  styleUrls: ['./error-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorViewComponent {
  readonly enums = {
    ErrorType,
  };

  type$: ReplaySubject<ErrorType | null> = new ReplaySubject<ErrorType | null>(1);

  @Input() set errorType(val: ErrorType | null | undefined) {
    this.type$.next(val);
  }
}
