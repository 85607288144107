import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateTimeConverter {
  fromModel(date: Date | null | undefined): string {
    if (!date) {
      return '00:00:00';
    }

    return moment(date).format('HH:mm:ss');
  }

  toModel(time: string): { h: number; m: number; s: number } {
    if (!time) {
      return {
        h: 0,
        m: 0,
        s: 0,
      };
    }

    const parts = time.split(':');
    if (![2, 3].includes(parts.length)) {
      throw new Error('Wrong time format!');
    }

    const intParts = parts.map((i) => parseInt(i, 10));

    return {
      h: intParts[0],
      m: intParts[1],
      s: intParts[2] ?? 0,
    };
  }
}
