import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LOCATION } from '@ng-web-apis/common';
import { EMPTY, Observable } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';

export interface AppConfig {
  api: string;
  adminApi: string;
  storageUrl: string;
  frontendUrl: string;
  server: string;
}

@Injectable()
export class AppConfigService<T = AppConfig> {
  private config?: T;

  constructor(private http: HttpClient, @Inject(LOCATION) private location: Location) {}

  get(): T | null {
    return this.config ? this.config : null;
  }

  init$(): Observable<void> {
    const configUrl = `${this.location.origin}/assets/config.json`;
    return this.http.get(configUrl).pipe(
      tap((r) => {
        this.config = r as T;
      }),
      mapTo(undefined),
      catchError(() => {
        return EMPTY;
      })
    );
  }
}
