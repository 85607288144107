import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { redirectParamName } from '@guards/redirect-param-name.const';
import { AuthClient } from '@services';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { RoutePaths } from '../routes-constants';

@Injectable({
  providedIn: 'root',
})
export class NotAuthenticatedGuard implements CanActivate {
  constructor(private auth: AuthClient, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const redirectToValue = route.queryParams[redirectParamName];

    const redirectLink = redirectToValue
      ? this.router.parseUrl(redirectToValue)
      : this.router.createUrlTree([RoutePaths.SiteRootRoute.root]);

    return this.auth.isAuthenticated().pipe(
      first(),
      map((isAuthenticated) => {
        return isAuthenticated ? redirectLink : true;
      })
    );
  }
}
