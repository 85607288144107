import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TransliterationService {
  transliterate(text: string): string {
    return text
      .split('')
      .map((char) => {
        return TransliterationService.convertChar(char);
      })
      .join('');
  }

  private static convertChar(char: string): string {
    switch (char) {
      case 'А':
      case 'а':
        return 'a';
      case 'Б':
      case 'б':
        return 'b';
      case 'В':
      case 'в':
        return 'v';
      case 'Г':
      case 'г':
        return 'g';
      case 'Д':
      case 'д':
        return 'd';
      case 'Е':
      case 'е':
        return 'e';
      case 'Ё':
      case 'ё':
        return 'yo';
      case 'Ж':
      case 'ж':
        return 'zh';
      case 'З':
      case 'з':
        return 'z';
      case 'И':
      case 'и':
        return 'i';
      case 'Й':
      case 'й':
        return 'j';
      case 'К':
      case 'к':
        return 'k';
      case 'Л':
      case 'л':
        return 'l';
      case 'М':
      case 'м':
        return 'm';
      case 'Н':
      case 'н':
        return 'n';
      case 'О':
      case 'о':
        return 'o';
      case 'П':
      case 'п':
        return 'p';
      case 'Р':
      case 'р':
        return 'r';
      case 'С':
      case 'с':
        return 's';
      case 'Т':
      case 'т':
        return 't';
      case 'У':
      case 'у':
        return 'u';
      case 'Ф':
      case 'ф':
        return 'f';
      case 'Х':
      case 'х':
        return 'h';
      case 'Ц':
      case 'ц':
        return 'tc';
      case 'Ч':
      case 'ч':
        return 'ch';
      case 'Ш':
      case 'ш':
        return 'sh';
      case 'Щ':
      case 'щ':
        return 'shch';
      case 'Ъ':
      case 'ъ':
        return '';
      case 'Ы':
      case 'ы':
        return 'yi';
      case 'Ь':
      case 'ь':
        return '';
      case 'Э':
      case 'э':
        return 'e';
      case 'Ю':
      case 'ю':
        return 'yu';
      case 'Я':
      case 'я':
        return 'ya';
      case ' ':
      case '/':
      case '|':
      case '\\':
      case ',':
      case '.':
      case ':':
      case '&':
      case '?':
      case '$':
      case '#':
      case '@':
      case '!':
      case '+':
      case '*':
      case ';':
        return '-';
      default:
        return char;
    }
  }
}
