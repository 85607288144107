import { DOCUMENT, Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { ErrorLayoutService, ErrorType } from '@services/error-layout.service';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'math-error-layout',
  templateUrl: './error-layout.component.html',
  styleUrls: ['./error-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ErrorLayoutComponent implements OnInit, OnDestroy {
  private readonly rootClassName = 'error-layout';

  readonly enums = {
    ErrorType,
  };

  type$: ReplaySubject<ErrorType | null> = new ReplaySubject<ErrorType | null>(1);

  @Input() set errorType(val: ErrorType | null | undefined) {
    this.type$.next(val);
  }

  constructor(
    public errorService: ErrorLayoutService,
    private location: Location,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.document.documentElement, this.rootClassName);
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.documentElement, this.rootClassName);
  }

  handleBack(): void {
    this.location.back();
  }
}
