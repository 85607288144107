import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { SiteTitleService } from '@services';

@Component({
  selector: 'math-server-error',
  template: `
    <h1>Ошибка 500: Сервер приуныл</h1>
    <article>
      <p>Кажется, сервер себя чувствует не очень.</p>
      <p>Попробуйте зайти на эту страницу позже.</p>
    </article>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServerErrorComponent {
  constructor(private titleService: SiteTitleService, private meta: Meta) {
    titleService.setTitle('Ууупс, кажется, что-то пошло не так...');
    const description = 'Ошибка сервера';
    meta.addTag({ name: 'description', content: description });
  }
}
