import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from '@guards';
import { RoutePaths } from './routes-constants';

const routes: Routes = [
  {
    path: RoutePaths.ErrorRoute.root,
    loadChildren: () => import('./pages/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: RoutePaths.AuthRoute.root,
    loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [],
  },
  {
    path: RoutePaths.ManageRoute.rootForModule,
    loadChildren: () => import('./pages/manage/manage.module').then((m) => m.ManageModule),
    canActivate: [AuthenticatedGuard],
  },
  {
    path: '**',
    loadChildren: () => import('./pages/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
