import { HttpClient } from '@angular/common/http';

type HttpRequestParameters = Parameters<typeof HttpClient.prototype.request>;
type HttpRequestOptions = HttpRequestParameters[2];

export class ApiClientBase {
  protected transformOptions(options: HttpRequestOptions): Promise<HttpRequestOptions | undefined> {
    if (!options) {
      return Promise.resolve(options);
    }

    const opts = {
      ...options,
      withCredentials: true,
    };

    return Promise.resolve(opts);
  }
}
