<div class="cover" [ngClass]="{'cover--not-found': ((type$ | async) === enums.ErrorType.NotFound)}"></div>
<header *ngIf="errorService.showMenu$ | async">
  <menu>
    <ul>
      <li>
        <a routerLink="/for-entrants">Абитуриентам</a>
      </li>
      <li>
        <a routerLink="/for-students">Студентам</a>
      </li>
      <li>
        <a routerLink="/for-scholars">Школам</a>
      </li>
      <li>
        <a routerLink="/contacts">Контакты</a>
      </li>
    </ul>
  </menu>
</header>

<div id="content">
  <ng-content></ng-content>
  <nav>
    <button (click)="handleBack()" class="btn back">Вернуться назад</button>
    <a routerLink="/" class="btn home">На главную</a>
  </nav>
</div>
