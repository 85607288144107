import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { redirectParamName } from '@guards/redirect-param-name.const';
import { AuthClient } from '@services';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { RoutePaths } from '../routes-constants';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate {
  constructor(private auth: AuthClient, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const redirectToValue = route.queryParams[redirectParamName];

    const redirectLink = redirectToValue
      ? this.router.parseUrl(redirectToValue)
      : this.router.createUrlTree([RoutePaths.AuthRoute.root], {
          queryParams: { [redirectParamName]: state.url },
        });

    return this.auth.isAuthenticated().pipe(
      first(),
      map((isAuthenticated) => {
        return isAuthenticated ? true : redirectLink;
      })
    );
  }
}
