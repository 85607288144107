import { Inject, Injectable, Optional } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { MathApiException } from '@services/api.generated';
import { Response } from 'express';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  ObservableInput,
  ObservedValueOf,
  OperatorFunction,
  throwError,
} from 'rxjs';
import { catchError, distinctUntilChanged, map, tap } from 'rxjs/operators';

export enum ErrorType {
  BadRequest = 'bad-request',
  Forbidden = 'forbidden',
  NotFound = 'not-found',
  ServerError = 'server-error',
}

export function handleResponseError<T, O extends ObservableInput<J>, J>(
  errorLayoutService: ErrorLayoutService
): OperatorFunction<T, T | ObservedValueOf<O>> {
  return (source) =>
    source.pipe(
      catchError((e: unknown) => {
        if (e instanceof MathApiException) {
          switch (e.status) {
            case 400:
              errorLayoutService.enable(ErrorType.BadRequest);
              break;
            case 403:
              errorLayoutService.enable(ErrorType.Forbidden);
              break;
            case 404:
              errorLayoutService.enable(ErrorType.NotFound);
              break;
            case 500:
            default:
              errorLayoutService.enable(ErrorType.ServerError);
              break;
          }

          return EMPTY;
        }

        errorLayoutService.enable(ErrorType.ServerError);

        return throwError(e);
      })
    );
}

export function handleServerResponse<T>(errorLayoutService: ErrorLayoutService): OperatorFunction<T, unknown> {
  return (source) => {
    return source.pipe(
      tap((data) => {
        if (!data) {
          errorLayoutService.enable(ErrorType.NotFound);
        }
      }),
      handleResponseError(errorLayoutService)
    );
  };
}

@Injectable()
export class ErrorLayoutService {
  private errorType: BehaviorSubject<ErrorType | undefined> = new BehaviorSubject<ErrorType | undefined>(undefined);

  constructor(@Optional() @Inject(RESPONSE) private res: Response) {}

  get enabled$(): Observable<boolean> {
    return this.errorType.asObservable().pipe(
      distinctUntilChanged(),
      map((t) => t !== undefined)
    );
  }

  get type$(): Observable<ErrorType | undefined> {
    return this.errorType.asObservable().pipe(distinctUntilChanged());
  }

  get showMenu$(): Observable<boolean> {
    return this.type$.pipe(map((t) => t !== ErrorType.ServerError));
  }

  enable(type: ErrorType): void {
    this.errorType.next(type);
    this.setResponseCode(type);
  }

  disable(): void {
    this.errorType.next(undefined);
    this.setResponseCode(undefined);
  }

  private setResponseCode(type: ErrorType | undefined) {
    if (type === undefined) {
      this.res?.status(200);
      return;
    }

    let code;

    switch (type) {
      case ErrorType.BadRequest:
        code = 400;
        break;
      case ErrorType.Forbidden:
        code = 403;
        break;
      case ErrorType.ServerError:
        code = 500;
        break;

      case ErrorType.NotFound:
      default:
        code = 404;
    }

    this.res?.status(code);
  }
}
