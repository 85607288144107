export const RoutePaths = {
  SiteRootRoute: {
    root: '/',
  },
  AuthRoute: {
    root: 'auth',
    signIn: 'sign-in',
    confirmEmail: 'confirm-email',
    continueRegistration: 'continue-registration',
    resetPassword: 'reset-password',
    continuePasswordReset: 'continue-password-reset',
  },
  ErrorRoute: {
    root: 'error',

    notFound: 'not-found',
    notFoundCode: '404',

    forbidden: 'forbidden',
    forbiddenCode: '403',

    badRequest: 'bad-request',
    badRequestCode: '400',

    serverError: 'server-error',
    serverErrorCode: '500',
  },
  ManageRoute: {
    rootForModule: '',
    dashboard: 'dashboard',
    pages: {
      root: 'pages',
      create: 'create',
      edit: 'edit',
      list: 'list',
      removed: 'removed',
    },
    news: {
      root: 'news',
      create: 'create',
      edit: 'edit',
      list: 'list',
      removed: 'removed',
    },
    events: {
      root: 'events',
      create: 'create',
      edit: 'edit',
      list: 'list',
      removed: 'removed',
    },
    files: 'files',
    persons: {
      root: 'persons',
      create: 'create',
      edit: 'edit',
      list: 'list',
    },
    users: {
      root: 'users',
      create: 'create',
      edit: 'edit',
      list: 'list',
    },
    categories: {
      root: 'categories',
      create: 'create',
      edit: 'edit',
      list: 'list',
    },
    professors: {
      root: 'professors',
      create: 'create',
      edit: 'edit',
      list: 'list',
    },
    settings: 'settings',
  },
};
