import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SettingsClient } from '@services';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';

@Injectable()
export class SiteTitleService {
  private readonly pageTitle$: Subject<string | null> = new BehaviorSubject<string | null>(null);
  private readonly isHomePage$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly description$: Subject<string | null> = new BehaviorSubject<string | null>(null);
  private readonly seoTitle$: Subject<string | null> = new BehaviorSubject<string | null>(null);

  private set pageTitle(val: string | null) {
    this.pageTitle$.next(val);
  }

  private set isHomePage(val: boolean) {
    this.isHomePage$.next(val);
  }

  constructor(
    private titleService: Title,
    private meta: Meta,
    private siteSettingsClient: SettingsClient,
    private destroy$: TuiDestroyService
  ) {
    this.setTitleUpdater();
  }

  setHomeTitle(): void {
    this.isHomePage = true;
    this.pageTitle = null;
  }

  setTitle(title: string | null): void {
    this.isHomePage = false;
    this.pageTitle = title;
  }

  setSeoTitle(seoTitle: string | null) {
    this.seoTitle$.next(seoTitle);
  }

  setSeoDescription(description: string | null): void {
    this.description$.next(description);
  }

  private setTitleUpdater(): void {
    const pageSettings$ = this.siteSettingsClient.getIndexModel().pipe(shareReplay(1));

    combineLatest([
      combineLatest([
        pageSettings$.pipe(map((m) => m.siteName)),
        pageSettings$.pipe(map((m) => m.defaultTitleForHomePage)),
        pageSettings$.pipe(map((m) => m.titleDelimiter)),
      ]),
      this.pageTitle$,
      this.isHomePage$,
      combineLatest([this.description$, this.seoTitle$]),
    ])
      .pipe(
        tap((values) => {
          const [[, defaultHomePageTitle], pageTitle, isHomePage, [seoDescription, seoTitle]] = values;

          const title = isHomePage ? defaultHomePageTitle : seoTitle ?? pageTitle;
          const description = isHomePage
            ? 'Главная страница сайта Математического факультета ЯрГУ'
            : seoDescription ?? '';

          if (title) {
            this.meta.updateTag({
              name: 'title',
              content: title,
            });
          }

          this.meta.updateTag({
            name: 'description',
            content: description,
          });
        }),
        map((values) => {
          const [[siteName, defaultHomePageTitle, titleDelimiter], pageTitle, isHomePage] = values;

          if (pageTitle) {
            return `${pageTitle}${titleDelimiter}${siteName}`;
          }

          return isHomePage ? `${defaultHomePageTitle}${titleDelimiter}${siteName}` : siteName;
        }),
        tap((value) => this.titleService.setTitle(value)),
        map(() => {}),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
