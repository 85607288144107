import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { SiteTitleService } from '@services';

@Component({
  selector: 'math-bad-request',
  template: `
    <h1>Упс, кажется, что-то пошло не так</h1>
    <article>Серверу послан некорректный запрос</article>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadRequestComponent {
  constructor(private titleService: SiteTitleService, private meta: Meta) {
    titleService.setTitle('Ууупс, кажется, что-то пошло не так...');
    const description = '400 ошибка';
    meta.addTag({ name: 'description', content: description });
  }
}
