import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { SiteTitleService } from '@services';

@Component({
  selector: 'math-forbidden',
  template: `
    <h1>Доступ запрещен</h1>
    <article>Кажется, вы потерялись</article>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForbiddenComponent {
  constructor(private titleService: SiteTitleService, private meta: Meta) {
    titleService.setTitle('Доступ запрещён: 403 ошибка');
    const description = '403 ошибка';
    meta.addTag({ name: 'description', content: description });
  }
}
