import { Inject, Injectable, InjectionToken } from '@angular/core';
import { merge, Observable, of, OperatorFunction, timer } from 'rxjs';
import { catchError, mapTo, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

const FLICKER_FREE_TIME = 75;

export const FLICKER_FREE_TIME_TOKEN = new InjectionToken('FLICKER_FREE_TIME', { factory: () => FLICKER_FREE_TIME });

export function getDataWithLoadingStatus<T>(
  time: number = FLICKER_FREE_TIME
): OperatorFunction<T, [Observable<T>, Observable<boolean>]> {
  return (source) => {
    const res$ = source.pipe(shareReplay(1));
    const resWithoutError$ = res$.pipe(
      mapTo(true),
      catchError(() => of(false))
    );

    const loading$ = merge(timer(time).pipe(mapTo(true), takeUntil(res$)), resWithoutError$.pipe(mapTo(false)));

    return of([res$, loading$]);
  };
}

export function extractDataAndLoading$<T>(
  res$: Observable<[Observable<T>, Observable<boolean>]>
): [Observable<T>, Observable<boolean>] {
  return [res$.pipe(switchMap(([d$]) => d$)), res$.pipe(switchMap(([, loading$]) => loading$))];
}

@Injectable()
export class LoadingStatusService {
  constructor(@Inject(FLICKER_FREE_TIME_TOKEN) private time: number) {}

  getDataWithLoadingStatus<T>(data$: Observable<T>): [Observable<T>, Observable<boolean>] {
    const res$ = data$.pipe(getDataWithLoadingStatus(this.time));

    return extractDataAndLoading$(res$);
  }
}
