/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TransferState, StateKey, makeStateKey } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

export function isString(value: unknown): value is string {
  return Object.prototype.toString.apply(value) === '[object String]';
}

@Injectable({ providedIn: 'root' })
export class TransferHttpService {
  constructor(
    protected transferState: TransferState,
    private httpClient: HttpClient,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  request<T>(
    method: string,
    uri: string | Request,
    options?: {
      body?: unknown;
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      reportProgress?: boolean;
      observe?: 'response';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.getData<T>(method, uri, options, (_method, _url, _options: any) => {
      return this.httpClient.request<T>(_method, _url as string, _options);
    });
  }

  /**
   * Performs a request with `get` http method.
   */
  get<T>(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'response';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.getData<T>('get', url, options, (_method: string, _url, _options: any) => {
      return this.httpClient.get<T>(_url as string, _options);
    });
  }

  /**
   * Performs a request with `post` http method.
   */
  post<T>(
    url: string,
    body: unknown,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'response';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.getPostData<T>('post', url, body, options, (_method, _url, _body, _options: any) => {
      return this.httpClient.post<T>(_url as string, _body, _options);
    });
  }

  /**
   * Performs a request with `put` http method.
   */
  put<T>(
    url: string,
    body: any,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.getPostData<T>('put', url, body, options, (_method, _url, _body, _options: any) => {
      return this.httpClient.put<T>(_url as string, _body, _options);
    });
  }

  /**
   * Performs a request with `delete` http method.
   */
  delete<T>(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'response';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.getData<T>('delete', url, options, (_method, _url, _options: any) => {
      return this.httpClient.delete<T>(_url as string, _options);
    });
  }

  /**
   * Performs a request with `patch` http method.
   */
  patch<T>(
    url: string,
    body: unknown,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'response';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.getPostData<T>('patch', url, body, options, (_method, _url, _body, _options: any): Observable<any> => {
      return this.httpClient.patch<T>(_url as string, _body, _options);
    });
  }

  /**
   * Performs a request with `head` http method.
   */
  head<T>(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'response';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.getData<T>('head', url, options, (_method, _url, _options: any) => {
      return this.httpClient.head<T>(_url as string, _options);
    });
  }

  /**
   * Performs a request with `options` http method.
   */
  options<T>(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'response';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.getData<T>('options', url, options, (_method, _url, _options: any) => {
      return this.httpClient.options<T>(_url as string, _options);
    });
  }

  private getData<T>(
    method: string,
    uri: string | Request,
    options: unknown,
    callback: (method: string, uri: string | Request, options: unknown) => Observable<unknown>
  ): Observable<T> {
    let url: string;

    if (!isString(uri)) {
      url = uri.url;
    } else {
      url = uri;
    }

    const tempKey = url + (options ? JSON.stringify(options) : '');
    const key = makeStateKey<T>(tempKey);
    try {
      return this.resolveData<T>(key);
    } catch (e) {
      return callback(method, uri, options).pipe(
        map((v) => v as T),
        tap((data: T) => {
          if (isPlatformBrowser(this.platformId)) {
            // Client only code.
            // nothing;
          }
          if (isPlatformServer(this.platformId)) {
            this.setCache<T>(key, data);
          }
        })
      );
    }
  }

  private getPostData<T>(
    _method: string,
    uri: string | Request,
    body: unknown,
    options: unknown,
    callback: (method: string, uri: string | Request, body: unknown, options: unknown) => Observable<unknown>
  ): Observable<T> {
    let url = uri;

    if (!isString(uri)) {
      url = uri.url;
    }

    const tempKey = url + (body ? JSON.stringify(body) : '') + (options ? JSON.stringify(options) : '');
    const key = makeStateKey<T>(tempKey);

    try {
      return this.resolveData<T>(key);
    } catch (e) {
      return callback(_method, uri, body, options).pipe(
        map((v) => v as T),
        tap((data: T) => {
          if (isPlatformBrowser(this.platformId)) {
            // Client only code.
            // nothing;
          }
          if (isPlatformServer(this.platformId)) {
            this.setCache<T>(key, data);
          }
        })
      );
    }
  }

  private resolveData<T>(key: StateKey<T>): Observable<T> {
    const data = this.getFromCache<T>(key);

    if (!data) {
      throw new Error();
    }

    if (isPlatformBrowser(this.platformId)) {
      // Client only code.
      this.transferState.remove(key);
    }
    if (isPlatformServer(this.platformId)) {
      // Server only code.
    }

    return of(data);
  }

  private setCache<T>(key: StateKey<T>, data: T): void {
    return this.transferState.set<T>(key, data);
  }

  private getFromCache<T>(key: StateKey<T>): T | null {
    return this.transferState.get<T | null>(key, null);
  }
}
